import * as React from "react"
import styled from 'styled-components';
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

//Components
import ProjectInfo from '../../components/ProjectInfo';
import ProjectHeader from '../../components/ProjectHeader';
import ProjectSubHeaderBlank from "../../components/ProjectSubHeaderBlank";
import FooterWithPadding from '../../components/FooterWithPadding';

const FamilyAppPage = () => {

  const data = useStaticQuery(graphql`
  query {

    faIosOne: file(relativePath: { eq: "family-app/ios/fa-ios-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faIosTwo: file(relativePath: { eq: "family-app/ios/fa-ios-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faIosThree: file(relativePath: { eq: "family-app/ios/fa-ios-3.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faIosFour: file(relativePath: { eq: "family-app/ios/fa-ios-4.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faIosFive: file(relativePath: { eq: "family-app/ios/fa-ios-5.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faIosSix: file(relativePath: { eq: "family-app/ios/fa-ios-6.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faIosSeven: file(relativePath: { eq: "family-app/ios/fa-ios-7.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faIosEight: file(relativePath: { eq: "family-app/ios/fa-ios-8.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faIosNine: file(relativePath: { eq: "family-app/ios/fa-ios-9.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    faAndroidOne: file(relativePath: { eq: "family-app/android/fa-android-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faAndroidTwo: file(relativePath: { eq: "family-app/android/fa-android-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faAndroidThree: file(relativePath: { eq: "family-app/android/fa-android-3.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faAndroidFour: file(relativePath: { eq: "family-app/android/fa-android-4.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faAndroidFive: file(relativePath: { eq: "family-app/android/fa-android-5.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faAndroidSix: file(relativePath: { eq: "family-app/android/fa-android-6.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faAndroidSeven: file(relativePath: { eq: "family-app/android/fa-android-7.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faAndroidEight: file(relativePath: { eq: "family-app/android/fa-android-8.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faAndroidNine: file(relativePath: { eq: "family-app/android/fa-android-9.png" }) {
      childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid
        }
      }
    }


  }
`)

  return (
    <Grid>
      <ProjectHeader 
        title="Family App"
        subTitle="Hippa compliant messaging for the senior healthcare industry"
      />
      <ProjectInfo 
        when="2016 - 2018"
        platform="Mobile Native (iOS, Android) + Web"
        team="VP of Product, 2PMs, 2 Designers"
        role="UI/UX, User Testing, Analytics, Prototyping"
        tools="Sketch, Invision, Abstract, Framer, Protopie, Jira"

        symbolPlatform="📱"
      />


        <div className="company-info">
          <span>Caremerge Family App</span> connects family members with their loved one’s in senior care. 
          Its Hippa compliant messaging allows workers to easily give updates to family members via photos, 
          text, and participation in events. 
        </div>

        <div className="background">
          <p className="background-title">Background</p>
          <p className="background-text">
          I worked on this app over a period of two years in a supportive manner. 
          I would help on designing and ideating upcoming features, design QA, 
          and more while juggling other projects I was a part of at Caremerge.
          </p>
        </div>

      <ProjectSubHeaderBlank
        title="Selected Designs"
      />

      <ProjectShowcaseCard>
        <Images fluid={data.faIosOne.childImageSharp.fluid} />
        <Images fluid={data.faIosTwo.childImageSharp.fluid} />
        <Images fluid={data.faIosThree.childImageSharp.fluid} />
      </ProjectShowcaseCard>
      <ProjectShowcaseCard>
        <Images fluid={data.faIosFour.childImageSharp.fluid} />
        <Images fluid={data.faIosFive.childImageSharp.fluid} />
        <Images fluid={data.faIosSix.childImageSharp.fluid} />
      </ProjectShowcaseCard>
      <ProjectShowcaseCard>
        <Images fluid={data.faIosSeven.childImageSharp.fluid} />
        <Images fluid={data.faIosEight.childImageSharp.fluid} />
        <Images fluid={data.faIosNine.childImageSharp.fluid} />
      </ProjectShowcaseCard>
      <ProjectShowcaseCard>
        <Images fluid={data.faAndroidOne.childImageSharp.fluid} />
        <Images fluid={data.faAndroidTwo.childImageSharp.fluid} />
        <Images fluid={data.faAndroidThree.childImageSharp.fluid} />
      </ProjectShowcaseCard>
      <ProjectShowcaseCard>
        <Images fluid={data.faAndroidFour.childImageSharp.fluid} />
        <Images fluid={data.faAndroidFive.childImageSharp.fluid} />
        <Images fluid={data.faAndroidSix.childImageSharp.fluid} />
      </ProjectShowcaseCard>
      <ProjectShowcaseCard>
        <Images fluid={data.faAndroidSeven.childImageSharp.fluid} />
        <Images fluid={data.faAndroidEight.childImageSharp.fluid} />
        <Images fluid={data.faAndroidNine.childImageSharp.fluid} />
      </ProjectShowcaseCard>
    <FooterWithPadding />
    </Grid>
  )
}

export default FamilyAppPage




const Grid = styled.div`
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  max-width: 1110px;
  margin: 0 auto;

  .company-info {
    font-weight: 400;
    font-size: clamp( 16px,2vw,18px);
    line-height: 1.6;
    margin: 32px 0px;
    padding: 0px 0px 0px 11px;
    border-left: 3px solid var(--black-blue);
    color: var(--black-blue);
    span {
      font-weight: bold;
    }
    @media (max-width: 1200px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 850px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 650px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  @media (max-width: 650px) {
      p .star {
      display: none;
    }
  }

  .background {
    .background-title {
      font-weight: 600;
      font-size: clamp( 16px,2vw,18px);
      padding: 0px 0px 16px 0px;
      color: #0c0c1d;
    }
    .background-text {
      font-weight: 400;
      font-size: clamp( 16px,2vw,18px);
      line-height: 1.6;
      color: var(--off-black);
			padding-bottom: 2.5%;
      span {
        font-weight: 900;
      }
    }
    p .star {
      padding-right: 1%;
    }
    p span {
      font-weight: 900;
      padding-right: .5%;
    }
    @media (max-width: 1200px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 850px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 650px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`


const ProjectShowcaseCard = styled.div`
  border: .1rem solid #c7c7cc;
  box-shadow: 0 0.2rem 0.6rem 0 rgba(37,37,37,0.1);
  border-radius: 1rem;
  background: rgba(221, 221, 221, 0.11);
  padding: 48px;
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12%;

  @media (max-width: 900px) {
    padding: 24px;
  }
  @media (max-width: 1200px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`

const Images = styled(Img)`
  box-shadow: 0px 4px 12px 3px rgba(0, 0, 0, 0.15);
  border-radius: .5rem;
`


